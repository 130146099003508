import React from 'react';
// import { Button } from 'react-bootstrap';
import imgbombomsignup from '../../../../assets/images/logo/img-bombom-signup.png';
import cleanprologo from '../../../../assets/images/logo/Logo_Cleanproplus-white.png';
import './Responsive.css'

const StartScreen = ({ setPage }) => {
    return (
        <div id="start-screen" className="d-flex flex-column justify-content-between align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#004FC1" }} onClick={() => setPage('Option')} >
            <div className="content d-flex flex-column align-items-center" style={{ marginTop: '200px' }}>
                <h1 className="text-white" style={{ fontSize: '90px', fontWeight: '600', marginBottom: '5px' }}>Welcome</h1>
                <h1 className="text-white" style={{ fontSize: '90px', fontWeight: '600', marginBottom: '90px' }}>To</h1>
                <div className="logo-placeholder" style={{ width: '400px', height: '400px', marginBottom: '50px' }}>
                    <img
                        src={imgbombomsignup}
                        alt="cleanproWelcomeLogo"
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', display: 'block' }}
                    />
                </div>
                <div className="logo-placeholder" style={{ width: '600px', marginBottom: '300px' }}>
                    <img
                        src={cleanprologo}
                        alt="Cleanpro Plus Logo"
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', display: 'block' }}
                    />
                </div>
                <div
                    className="text-white"
                    style={{
                        fontSize: '60px',
                        fontWeight: '600',
                        cursor: 'pointer',
                    }}
                >
                    Touch to Start
                </div>
            </div>
            <div className="reminder-text text-white" style={{ position: "absolute", bottom: "120px", textAlign: "center", fontSize: '36px', fontWeight: '400' }}>
                Reminder: <br /> Check the machine you select <br />
                is not being used or is available
            </div>
            {/* <div className="language-options d-flex justify-content-end" style={{ position: "absolute", bottom: "40px", right: "40px", gap: "10px" }}>
                <Button
                    variant="outline-light"
                    style={{
                        padding: '10px 0',
                        fontSize: '36px',
                        fontWeight: '500',
                        borderRadius: '30px',
                        borderWidth: '2px',
                        width: '120px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    BM
                </Button>
                <Button
                    variant="outline-light"
                    style={{
                        padding: '10px 0',
                        fontSize: '36px',
                        fontWeight: '500',
                        borderRadius: '30px',
                        borderWidth: '2px',
                        width: '120px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    中文
                </Button>
                <Button
                    variant="outline-light"
                    style={{
                        padding: '10px 0',
                        fontSize: '36px',
                        fontWeight: '500',
                        borderRadius: '30px',
                        borderWidth: '2px',
                        width: '120px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    EN
                </Button>
            </div> */}
        </div>
    );
};

export default StartScreen;
