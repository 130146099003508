import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import VoucherType from "../charts/VoucherType";
import VoucherValue from "../charts/VoucherValue";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from "../../../../dynamicController";
import { Select, DatePicker, Row, Col, Button } from "antd";
// import moment from "moment";
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);
const { RangePicker } = DatePicker;
const { Option } = Select;

class VoucherRedemption extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: [],
			voucherType: [],
			voucherValue: [],
			loading: true,
			startDate: "",
			endDate: "",
			voucherTypeOption: [],
			voucherTypeFilter: "",
			outletIds: []
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated || !this.props.admin.admin.voucher_redemption) {
				return this.props.history.push("/admin/dashboard");
			} else {
				axios
					.post("/api/admin/report/voucher", {}, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						this.setState({
							...this.state,
							loading: false,
							data: res.data.data,
							voucherType: res.data.voucherType,
							voucherValue: res.data.voucherValue,
							voucherTypeOption: res.data.voucherType.map(data => data.voucherType)
						});
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleChange = e =>
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});

	handleDate = dates => {
		if (dates) {
			const [start, end] = dates;
			this.setState({
				startDate: start,
				endDate: end
			});
		} else {
			this.setState({
				startDate: null,
				endDate: null
			});
		}
	};

	handleOutletChange = outletIds => {
		this.setState({ outletIds });
	};

	handleFilter = e => {
		const { startDate, endDate, voucherTypeFilter, outletIds } = this.state;
		let sendThis = {

		};

		if (startDate && endDate) {
			sendThis.startDate = new Date(startDate);
			sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
		}

		if (voucherTypeFilter) {
			sendThis.voucherTypeFilter = voucherTypeFilter
		}

		if (outletIds.length > 0) {
			sendThis.outletIds = outletIds;
		}

		this.setState({
			...this.state,
			loading: true
		});
		axios
			.post("/api/admin/report/voucher", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data,
					voucherType: res.data.voucherType,
					voucherValue: res.data.voucherValue
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	handleReset = e => {
		this.setState({
			...this.state,
			startDate: "",
			endDate: "",
			loading: true,
			voucherTypeFilter: "",
			outletIds: []
		});

		axios
			.post("/api/admin/report/voucher", {}, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data,
					voucherType: res.data.voucherType,
					voucherValue: res.data.voucherValue
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	render() {
		let data = {
			columns: [
				{
					label: i18n.t("Member ID"),
					field: "member_id",
					sort: "asc"
				},
				{
					label: i18n.t("User Name"),
					field: "name",
					sort: "asc"
				},
				{
					label: i18n.t("Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Email"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Amount"),
					field: "voucherAmount",
					sort: "asc"
				},
				{
					label: i18n.t("Grant Date"),
					field: "grantDate",
					sort: "asc"
				},
				{
					label: i18n.t("Claim Date"),
					field: "claimDate",
					sort: "asc"
				},
				{
					label: i18n.t("Status"),
					field: "available",
					sort: "asc"
				},
				{
					label: i18n.t("Voucher Type"),
					field: "from",
					sort: "asc"
				},
				{
					label: i18n.t("Washer Use"),
					field: "washer_use",
					sort: "asc"
				},
				{
					label: i18n.t("Dryer Use"),
					field: "dryer_use",
					sort: "asc"
				},
				{
					label: i18n.t("Package Use"),
					field: "package_use",
					sort: "asc"
				},
			],
			rows: this.state.data
		};

		let csv = [];

		if (this.state.data.length > 0) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					member_id: this.state.data[i].member_id,
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					email: this.state.data[i].email,
					voucherAmount: this.state.data[i].voucherAmount,
					grantDate: this.state.data[i].grantDate,
					claimDate: this.state.data[i].claimDate,
					available: this.state.data[i].available,
					from: this.state.data[i].from,
					washer_use: this.state.data[i].washer_use,
					dryer_use: this.state.data[i].dryer_use,
					package_use: this.state.data[i].package_use
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "Member ID", key: "member_id" },
				{ label: "User Name", key: "name" },
				{ label: "Number", key: "number" },
				{ label: "Email", key: "email" },
				{ label: "Amount", key: "voucherAmount" },
				{ label: "Grant Date", key: "grantDate" },
				{ label: "Claim Date", key: "claimDate" },
				{ label: "Status", key: "available" },
				{ label: "Voucher Type", key: "from" },
				{ label: "Washer Use", key: "washer_use" },
				{ label: "Dryer Use", key: "dryer_use" },
				{ label: "Package Use", key: "package_use" },
			],
			data: csv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">{i18n.t("Voucher Redemption")}</h5>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>{i18n.t("Filter")}</h6>
									<Row gutter={16} className="mt-3">
										<Col span={24}>
											<label htmlFor="outletId">{i18n.t("Select Outlet(s)")}: </label>
											<Select
												id="outletId"
												mode="multiple"
												style={{ width: '100%' }}
												placeholder={i18n.t("Select Outlet(s)")}
												value={this.state.outletIds}
												onChange={this.handleOutletChange}
												showSearch
												optionFilterProp="label"
											>
												{
													this.props.admin?.admin?.outlets?.map(outlet => (
														<Option
															key={outlet.id}
															value={outlet.id}
															label={`${outlet.outlet_fullname} (${outlet.outlet_name})`}
														>
															{outlet.outlet_fullname} ({outlet.outlet_name})
														</Option>
													))
												}
											</Select>
										</Col>
									</Row>
									<Row gutter={16} className="mt-3">
										<Col span={24}>
											<label htmlFor="voucherTypeFilter" >{i18n.t("Select Voucher Type")}: </label>
											<Select
												id="voucherTypeFilter"
												style={{ width: '100%' }}
												placeholder={i18n.t("Select Voucher Type")}
												value={this.state.voucherTypeFilter}
												onChange={value => this.setState({ voucherTypeFilter: value })}
											>
												<Option disabled value="">{i18n.t("Select Voucher Type")}</Option>
												{this.state.voucherTypeOption.map((data, i) => (
													<Option key={i} value={data}>
														{i18n.t(data)}
													</Option>
												))}
											</Select>
										</Col>
									</Row>
									<Row gutter={16} className="mt-3">
										<Col span={24}>
											<label htmlFor="date">{i18n.t("Select Grant Date")}: </label>
											<RangePicker
												value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
												onChange={this.handleDate}
												style={{ width: '100%' }}
												ranges={{
													'Today': [dayjs(), dayjs()],
													'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
													'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
													'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
													'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
													'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
												}}
											/>
										</Col>
									</Row>

									<Row gutter={16} className="mt-3">
										<Col span={12}>
											<Button type="primary" onClick={this.handleFilter} className="w-100">
												{i18n.t("Filter")}
											</Button>
										</Col>
										<Col span={12}>
											<Button onClick={this.handleReset} className="w-100">
												{i18n.t("Reset")}
											</Button>
										</Col>
									</Row>
								</div>
								<div className="text-center mt-5">
									{this.state.loading ? <Loading /> : (
										<div>
											<div style={{ display: "flex" }}>
												<div style={{ width: "50%" }}>
													<VoucherType voucherData={this.state.voucherType} />
												</div>
												<div className="ps-5" style={{ width: "50%" }}>
													<VoucherValue voucherData={this.state.voucherValue} />
												</div>
											</div>

											<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
											<div className="d-grid gap-2 d-md-flex justify-content-md-end">
												<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`voucher_redemption_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
													{i18n.t("Download Report")}
												</CSVLink>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(VoucherRedemption);
