import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from '../../admin/components/Loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MDBDataTable } from 'mdbreact';
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from '../../../dynamicController';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Select } from 'antd';

const mySwal = withReactContent(Swal);
const { RangePicker } = DatePicker;

class DiscountCodeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: [],
            loading: true,
            outletId: [],
            startDate: "",
            endDate: ""
        };
    };

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.discount_code_report) {
            return this.props.history.push('/distributor');
        } else {
            const { distributorId } = this.props.distributor;
            axios.post('/api/admin/report/discountCodeReport', { distributorId: distributorId, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data.data
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleDate = dates => {
        if (dates) {
            const [start, end] = dates;
            this.setState({
                startDate: start,
                endDate: end
            });
        } else {
            this.setState({
                startDate: null,
                endDate: null
            });
        }
    };

    handleFilter = e => {
        const { outletId, startDate, endDate } = this.state;
        let sendThis = {
            distributorId: this.props.distributor.distributorId,
            role: "distributor"
        };

        if (outletId && outletId.length > 0) {
            sendThis.outletIds = outletId;
        }
        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate);
            sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
        }
        this.setState({
            ...this.state,
            loading: true
        });
        axios.post('/api/admin/report/discountCodeReport', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
            });
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            outletId: [],
            startDate: "",
            endDate: "",
            loading: true
        });
        const { distributorId } = this.props.distributor;
        axios.post('/api/admin/report/discountCodeReport', { distributorId: distributorId, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
            });
    };

    render() {
        let data = {
            columns: [
                {
                    label: i18n.t("Date"),
                    field: "date",
                    sort: "asc"
                },
                {
                    label: i18n.t("Discount Code"),
                    field: "code",
                    sort: "asc"
                },
                {
                    label: i18n.t("Discount Code Type"),
                    field: "discount_type",
                    sort: "asc"
                },
                {
                    label: i18n.t("Discount Code Amount"),
                    field: "discount_amount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Outlet"),
                    field: "outlet",
                    sort: "asc"
                },
                {
                    label: i18n.t("Number of Redemption"),
                    field: "total_redeem",
                    sort: "asc"
                },
                {
                    label: i18n.t("Total Discount Amount"),
                    field: "total_discount_amount",
                    sort: "asc"
                },
            ],
            rows: this.state.data
        };

        const csvData = {
            headers: [
                { label: "Date", key: "date" },
                { label: "Discount Code", key: "code" },
                { label: "Discount Code Type", key: "discount_type" },
                { label: "Discount Code Amount", key: "discount_amount" },
                { label: "Outlet", key: "outlet" },
                { label: "Number of Redemption", key: "total_redeem" },
                { label: "Total Discount Amount", key: "total_discount_amount" },
            ],
            data: this.state.data
        };


        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Discount Code Report")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <div>
                                        <label htmlFor="outletId">{i18n.t("Select Outlet")}: </label>
                                        <Select
                                            id="outletId"
                                            mode="multiple"
                                            style={{ width: '100%', marginBottom: '10px' }}
                                            placeholder={i18n.t("Select Outlet")}
                                            value={this.state.outletId}
                                            onChange={value => this.setState({ outletId: value })}
                                            optionFilterProp="children"
                                        >
                                            <Select.Option disabled value="">{i18n.t("Select Outlet")}</Select.Option>
                                            {
                                                this.props.distributor.outlet.map(outlet => (
                                                    <Select.Option key={outlet.id} value={outlet.id}>
                                                        {outlet.outlet_fullname} ({outlet.outlet_name})
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>

                                    <div>
                                        <label htmlFor="outletId">{i18n.t("Select Transaction Date")}: </label>
                                        <RangePicker
                                            onChange={this.handleDate}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                            value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
                                            ranges={{
                                                'Today': [dayjs(), dayjs()],
                                                'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                                'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                                'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                                'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                                'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                            }}
                                        />
                                    </div>

                                    <div className="d-flex">
                                        <button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">{i18n.t("Filter")}</button>
                                        <button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">{i18n.t("Reset")}</button>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    {
                                        this.state.loading ? <Loading /> : (
                                            <div>
                                                <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`discountcode_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                        {i18n.t("Download Report")}
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(DiscountCodeReport);