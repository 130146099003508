import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Accordion, Carousel } from 'react-bootstrap';
import i18n from "i18next";

import { faqImage } from "../../../dynamicController";

const mySwal = withReactContent(Swal);

class UserFaq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            defaultFaq: [],
            token: false,
            stamp: false,
            reload: false
        };
    };

    componentDidMount() {

        if (this.props.operatorId && process.env.REACT_APP_STORETYPE === "cleanpro") {
            axios
                .post("/api/user/getFaqGroup", { operatorId: this.props.operatorId })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data.data,
                        defaultFaq: faqImage(),
                        token: res.data.token,
                        stamp: res.data.stamp,
                        reload: res.data.reload,
                        loading: false
                    });
                })
                .catch(err => {
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
                });
        } else if (this.props.operatorId) {
            axios
                .post("/api/user/getFaq", { operatorId: this.props.operatorId })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data.data,
                        defaultFaq: faqImage(),
                        token: res.data.token,
                        stamp: res.data.stamp,
                        reload: res.data.reload,
                        loading: false
                    });
                })
                .catch(err => {
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
                });
        } else {
            this.setState({
                ...this.state,
                defaultFaq: faqImage(),
                loading: false
            });
        }
    }

    render() {

        const renderList = () => {

            if (this.state.loading) {
                return (
                    <div className="text-center">
                        <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"} role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                );
            } else if (process.env.REACT_APP_STORETYPE === "cleanpro") {
                return (
                    <div>
                        <Accordion>
                            {/* <Accordion.Item eventKey="0" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "Cleanpro Plus" })}>
                                <Accordion.Header><b>Cleanpro Plus</b></Accordion.Header>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "General questions" })}>
                                <Accordion.Header><b>{i18n.t("General questions")}</b></Accordion.Header>
                            </Accordion.Item>
                            {
                                this.state.stamp ? (
                                    <Accordion.Item eventKey="2" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "E-stamps collection" })}>
                                        <Accordion.Header><b>{i18n.t("E-stamps collection")}</b></Accordion.Header>
                                    </Accordion.Item>
                                ) : null
                            }
                            <Accordion.Item eventKey="3" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "Coin exchange machines" })}>
                                <Accordion.Header><b>{i18n.t("Coin exchange machines")}</b></Accordion.Header>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "Washer machines" })}>
                                <Accordion.Header><b>{i18n.t("Washer machines")}</b></Accordion.Header>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "Dryer machines" })}>
                                <Accordion.Header><b>{i18n.t("Dryer machines")}</b></Accordion.Header>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "Paywave issues" })}>
                                <Accordion.Header><b>{i18n.t("Paywave issues")}</b></Accordion.Header>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7" className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { title: "Cleanpro franchise" })}>
                                <Accordion.Header><b>{i18n.t("Cleanpro franchise")}</b></Accordion.Header>
                            </Accordion.Item> */}
                            {
                                this.state.data.map((faqGroup, i) => {
                                    let faqGroupLang = faqGroup.faq_group_langs.filter(group => group.language === this.props.language)[0]
                                    return (
                                        <Accordion.Item eventKey={faqGroup.id} key={i} className="cleanpro-default-faq-group" onClick={e => this.props.history.push("/faq/details", { id: faqGroup.id })}>
                                            <Accordion.Header><b>{faqGroupLang ? faqGroupLang.title : faqGroup.title}</b></Accordion.Header>
                                        </Accordion.Item>
                                    );
                                })
                            }
                        </Accordion>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>{i18n.t("How to pay?")}</b></Accordion.Header>
                                <Accordion.Body>
                                    <Carousel variant="dark" interval={null} style={{ height: "550px", width: "90vW" }}>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[0]}
                                                alt="step1"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 1")}</b>: {i18n.t("After scan the QR code on the machine, select either register new account, login your account or skip to payment.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[1]}
                                                alt="step2"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2-1")}</b>: {i18n.t("For Dryer – Select your desired duration. Different duration will have different prices.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[2]}
                                                alt="step3"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2-2")}</b>: {i18n.t("For Washer – Select your desired water temperature. Different water temperature will have different prices.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[3]}
                                                alt="step4"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 3")}</b>: {i18n.t("For login user, Click on ‘Select Voucher’ to select your voucher.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[4]}
                                                alt="step5"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 4")}</b>: {i18n.t("Click on ‘PAY’ button to pay. For login user, select your payment method to pay.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[5]}
                                                alt="step5"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 5")}</b>: {i18n.t("After confirm the price and machine details, click on ‘OK’ button to pay.")}</p>
                                        </Carousel.Item>
                                    </Carousel>
                                </Accordion.Body>
                            </Accordion.Item>
                            {
                                this.state.token && this.state.reload ? (
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><b>{i18n.t("How to reload your e-Token?")}</b></Accordion.Header>
                                        <Accordion.Body>
                                            <Carousel variant="dark" interval={null} style={{ height: "550px", width: "90vW" }}>
                                                <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                                    <img
                                                        src={this.state.defaultFaq[6]}
                                                        alt="step1"
                                                        style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                                    />
                                                    <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 1")}</b>: {i18n.t('Click on the "Reload" button.')}</p>
                                                </Carousel.Item>
                                                <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                                    <img
                                                        src={this.state.defaultFaq[7]}
                                                        alt="step2"
                                                        style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                                    />
                                                    <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2")}</b>: {i18n.t("Select the package that you wish to purchase.")}</p>
                                                </Carousel.Item>
                                                <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                                    <img
                                                        src={this.state.defaultFaq[8]}
                                                        alt="step3"
                                                        style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                                    />
                                                    <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 3")}</b>: {i18n.t('Click on "Purchase" as confirmation before payment page.')}</p>
                                                </Carousel.Item>
                                            </Carousel>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ) : null
                            }
                            {
                                this.state.token && this.state.reload ? (
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><b>{i18n.t("Is e-token can be refund/transfer back to e-wallet?")}</b></Accordion.Header>
                                        <Accordion.Body>
                                            <p style={{ textAlign: "justify" }}>{i18n.t('No')}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ) : null
                            }
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><b>{i18n.t("Why can't I applied my voucher?")}</b></Accordion.Header>
                                <Accordion.Body>
                                    <img
                                        src={this.state.defaultFaq[9]}
                                        alt="step1"
                                        className="img-fluid"
                                        style={{ display: 'block', margin: "auto" }}
                                    />
                                    <p style={{ textAlign: "justify" }}>{i18n.t("When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.")}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header><b>{i18n.t("How to apply my voucher?")}</b></Accordion.Header>
                                <Accordion.Body>
                                    <Carousel variant="dark" interval={null} style={{ height: "550px", width: "90vW" }}>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[10]}
                                                alt="step1"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 1")}</b>: {i18n.t('Click on the "Select Voucher" button.')}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[11]}
                                                alt="step2"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 2")}</b>: {i18n.t("Select the voucher that you wish to apply.")}</p>
                                        </Carousel.Item>
                                        <Carousel.Item style={{ visibility: "initial", width: "90vW" }}>
                                            <img
                                                src={this.state.defaultFaq[12]}
                                                alt="step3"
                                                style={{ padding: "0px 70px", maxWidth: "100%", width: "auto", display: "block", margin: "auto" }}
                                            />
                                            <p style={{ textAlign: "justify" }}><b>{i18n.t("Step 3")}</b>: {i18n.t('Click on "Pay" once you have verified the voucher is successfully applied.')}</p>
                                        </Carousel.Item>
                                    </Carousel>
                                </Accordion.Body>
                            </Accordion.Item>
                            {
                                this.state.data.map((faq, i) => {
                                    let faqLang = faq.faq_langs.filter(faq_lang => faq_lang.language === this.props.language)[0]
                                    return (
                                        <Accordion.Item eventKey={faq.id} key={i}>
                                            <Accordion.Header><b>{faqLang ? faqLang.question : faq.question}</b></Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    faq.image ? <img src={faq.image} alt="faqImage" className="img-fluid" style={{ padding: "10px 10px 10px 10px" }} /> : null
                                                }
                                                <div dangerouslySetInnerHTML={{ __html: faqLang ? faqLang.answer : faq.answer }}></div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }
                        </Accordion>
                    </div>
                );
            }
        }

        return (
            <div id="setting-page" className="user-faq">
                <div id="setting-main">
                    <div id="user-header">
                        <h5><i onClick={e => this.props.history.goBack()} className="bi bi-chevron-left me-3"></i> <strong>{process.env.REACT_APP_STORETYPE === "cleanpro" ? i18n.t("FAQs") : i18n.t("Need help?")}</strong></h5>
                    </div>

                    <div id="user-body">
                        {
                            renderList()
                        }
                    </div>
                </div>
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        language: state.user.language
    };
};

export default connect(mapStateToProps, null)(UserFaq);