import { connect } from "react-redux";
import { changePage } from "../../../redux/actions/userActions";
import { useHistory } from "react-router-dom";

import CleanproCheck from "./component/Check/CleanproCheck";
import LaundroCheck from "./component/Check/LaundroCheck";
import WashupCheck from "./component/Check/WashupCheck";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Check = ({ change, showButton, outlet, laundroLite }) => {
  const history = useHistory();

  const redirectPaymentPage = (e) => {
    e.preventDefault();

    if (outlet.outletCode && outlet.operatorCode && !outlet.machineNo) {
      history.push("/user/dng/payment");
    } else {
      history.push("/user/payment");
    }

  };

  return (
    <div>
      {
        STORETYPE === "cleanpro"
          ? <CleanproCheck change={change} showButton={showButton} laundroLite={laundroLite} redirectPaymentPage={redirectPaymentPage} />
          : STORETYPE === "washup"
            ? <WashupCheck change={change} showButton={showButton} laundroLite={laundroLite} redirectPaymentPage={redirectPaymentPage} />
            : <LaundroCheck change={change} showButton={showButton} laundroLite={laundroLite} redirectPaymentPage={redirectPaymentPage} />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    outlet: state.payment.outlet
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (page) => dispatch(changePage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Check);
