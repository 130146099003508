import { connect } from 'react-redux';
import WasherLogo from '../../../../assets/images/logo/WasherLogo.png';
import VendingLogo from '../../../../assets/images/logo/VendingLogo.png';
import DryerLogo from '../../../../assets/images/logo/DryerLogo.png';
import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import './Responsive.css'

const SelectService = ({ onMachineTypeSelect, setPage, data, user }) => {

    return (
        <div id="select-service-screen" className="d-flex flex-column justify-content-between align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px" }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt="Home Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt="FAQ Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Faq', 'SelectService')}
                    />
                    <img
                        src={BackLogo}
                        alt="Back Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Option')}
                    />
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && data?.outlet_fullname && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data?.outlet_fullname}
                    </h2>
                )}
            </div>

            <h2 style={{ fontSize: '40px', marginBottom: '20px', marginTop: '40px', fontWeight: '600' }}>Welcome, {user ? user.name : ""}</h2>

            <div className="content d-flex flex-column justify-content-center align-items-center" style={{ flexGrow: 1 }}>
                <h3 className="text-dark text-center" style={{ fontSize: '50px', fontWeight: '750', marginBottom: '60px' }}>Select your service</h3>

                <div
                    style={{
                        width: '700px',
                        height: '200px',
                        fontSize: '55px',
                        fontWeight: '700',
                        color: '#FFFFFF',
                        backgroundColor: '#004FC1',
                        borderColor: '#004FC1',
                        borderRadius: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 50px',
                        marginBottom: '50px',
                    }}
                    onClick={() => onMachineTypeSelect('Washer')}
                >
                    <div style={{ textAlign: 'center', flex: 1 }}>
                        <h6 style={{ marginBottom: '10px', fontSize: '65px' }}>Washer</h6>
                    </div>
                    <div>
                        <img src={WasherLogo} alt="Washer Logo" style={{ height: '70px' }} />
                    </div>
                </div>

                <div
                    style={{
                        width: '700px',
                        height: '200px',
                        fontSize: '55px',
                        fontWeight: '700',
                        color: '#FFFFFF',
                        backgroundColor: '#004FC1',
                        borderColor: '#004FC1',
                        borderRadius: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 50px',
                        marginBottom: '50px',
                    }}
                    onClick={() => onMachineTypeSelect('Dryer')}
                >
                    <div style={{ textAlign: 'center', flex: 1 }}>
                        <h6 style={{ marginBottom: '10px', fontSize: '65px' }}>Dryer</h6>
                    </div>
                    <div>
                        <img src={DryerLogo} alt="Dryer Logo" style={{ height: '70px' }} />
                    </div>
                </div>

                <div
                    style={{
                        width: '700px',
                        height: '200px',
                        fontSize: '55px',
                        fontWeight: '700',
                        color: '#FFFFFF',
                        backgroundColor: '#004FC1',
                        borderColor: '#004FC1',
                        borderRadius: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 50px',
                    }}
                    onClick={() => onMachineTypeSelect('Vending Machine')}
                >
                    <div style={{ textAlign: 'center', flex: 1 }}>
                        <h6 style={{ marginBottom: '10px', fontSize: '65px' }}>Vending Machine</h6>
                    </div>
                    <div>
                        <img src={VendingLogo} alt="Vending Logo" style={{ height: '70px' }} />
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data
    };
};

export default connect(mapStateToProps, null)(SelectService);
