import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, Button, DatePicker, Row, Col, Divider } from "antd";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../../../admin/components/Loading";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from "../../../../dynamicController";
import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;
const mySwal = withReactContent(Swal);

class UserUsageReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            userUsageData: [],
            loading: false,
            outletIds: [],
            startDate: "",
            endDate: "",
            showReport: false,
            operatorId: "",
            outlets: [],
            distributorId: "",
            operators: [],
            country: ""
        };
    }
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (!this.props.client || !this.props.client.user_usage_report) {
                return this.props.history.push("/client");
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = (value, { id }) => {
        this.setState({
            [id]: value
        });
    };

    handleChangeDistributor = value => {
        const selectedOperator = this.props.client.admin.filter(admin => admin.distributorId === value);

        this.setState({
            operators: selectedOperator,
            distributorId: value,
            operatorId: "",
            outletIds: [],
            country: this.props.client.distributor.filter(distributor => distributor.id === value)[0].country
        });
    };

    handleChangeOperator = value => {
        const selectedOutlet = this.props.client.outlet.filter(outlet => outlet.adminId === value);

        this.setState({
            outlets: selectedOutlet,
            operatorId: value,
            outletIds: []
        });
    };

    handleDate = dates => {
        if (dates) {
            const [start, end] = dates;
            this.setState({
                startDate: start,
                endDate: end
            });
        } else {
            this.setState({
                startDate: null,
                endDate: null
            });
        }
    };

    handleFilter = e => {
        const { outletIds, startDate, endDate, operatorId, distributorId } = this.state;

        if (!distributorId) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please select distributor"), "error");
        } else if (!operatorId) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please select operator"), "error");
        } else if (outletIds.length === 0) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please select at least one outlet"), "error");
        } else {
            let sendThis = {
                operatorId
            };

            if (outletIds.length > 0) {
                sendThis.outletIds = outletIds;
            }
            if (startDate && endDate) {
                sendThis.startDate = new Date(startDate);
                sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
            }
            this.setState({ loading: true });
            axios.post('/api/admin/report/userUsageReport', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        loading: false,
                        userUsageData: res.data.data,
                        showReport: true
                    });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleReset = e => {
        this.setState({
            outletIds: [],
            startDate: null,
            endDate: null,
            userUsageData: [],
            loading: false,
            showReport: false,
            operatorId: "",
            outlets: [],
            distributorId: "",
            operators: [],
            country: ""
        });
    };

    render() {
        const { outletIds, userUsageData, showReport, operatorId, outlets, distributorId, operators } = this.state;
        const outletNames = this.props.client?.outlet?.reduce((acc, outlet) => {
            acc[outlet.id] = outlet.outlet_fullname;
            return acc;
        }, {});

        const tables = outletIds.map(outletId => {
            const data = userUsageData.filter(row => row.outlet_id === outletId);
            const tableData = {
                columns: [
                    { label: i18n.t("Customer Name"), field: "name", sort: "asc" },
                    { label: i18n.t("Member ID"), field: "member_id", sort: "asc" },
                    { label: i18n.t("Phone Number"), field: "number", sort: "asc" },
                    { label: i18n.t("Email Address"), field: "email", sort: "asc" },
                    { label: i18n.t("Number of Washer Transactions"), field: "washer_transactions", sort: "asc" },
                    { label: i18n.t("Number of Dryer Transactions"), field: "dryer_transactions", sort: "asc" },
                    { label: i18n.t("Vouchers Redeemed"), field: "vouchers_redeemed", sort: "asc" },
                    { label: i18n.t("Discount Code Redemption"), field: "discounts_redeemed", sort: "asc" },
                    { label: i18n.t("Total Spend"), field: "total_spend", sort: "asc" }
                ],
                rows: data.map(row => ({
                    ...row,
                    outlet_name: outletNames[outletId]
                }))
            };

            const csvData = {
                headers: [
                    { label: "Customer Name", key: "name" },
                    { label: "Member ID", key: "member_id" },
                    { label: "Phone Number", key: "number" },
                    { label: "Email Address", key: "email" },
                    { label: "Outlet Name", key: "outlet_name" },
                    { label: "Vouchers Redeemed", key: "vouchers_redeemed" },
                    { label: "Discount Code Redemption", key: "discounts_redeemed" },
                    { label: "Number of Washer Transactions", key: "washer_transactions" },
                    { label: "Number of Dryer Transactions", key: "dryer_transactions" },
                    { label: "Total Spend", key: "total_spend" }
                ],
                data: data.map(row => ({
                    name: row.name,
                    member_id: row.member_id,
                    number: `=""${row.number}""`,
                    email: row.email,
                    outlet_name: outletNames[outletId],
                    vouchers_redeemed: row.vouchers_redeemed,
                    discounts_redeemed: row.discounts_redeemed,
                    washer_transactions: row.washer_transactions,
                    dryer_transactions: row.dryer_transactions,
                    total_spend: row.total_spend
                }))
            };

            return (
                <div key={outletId} className="mt-5">
                    <Divider orientation="left">{outletNames[outletId]}</Divider>
                    <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                        <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_usage_report_${outletNames[outletId]}_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                            {i18n.t("Download Report")}
                        </CSVLink>
                    </div>
                </div>
            );
        });

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("User Usage Report")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label htmlFor="distributorId">{i18n.t("Select Distributor")}: </label>
                                            <Select
                                                id="distributorId"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Distributor")}
                                                value={distributorId}
                                                onChange={this.handleChangeDistributor}
                                            >
                                                <Option disabled value="">{i18n.t("Select Distributor")}</Option>
                                                {
                                                    this.props.client?.distributor?.map(distributor => (
                                                        <Option key={distributor.id} value={distributor.id}>
                                                            {distributor.name}
                                                        </Option>
                                                    ))
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="operatorId">{i18n.t("Select Operator")}: </label>
                                            <Select
                                                id="operatorId"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Operator")}
                                                value={operatorId}
                                                onChange={this.handleChangeOperator}
                                            >
                                                <Option disabled value="">{i18n.t("Select Operator")}</Option>
                                                {
                                                    distributorId ? operators.map(admin => (
                                                        <Option key={admin.id} value={admin.id}>
                                                            {admin.username}
                                                        </Option>
                                                    )) : null
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="outletId">{i18n.t("Select Outlet(s)")}: </label>
                                            <Select
                                                id="outletId"
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Outlet(s)")}
                                                value={outletIds}
                                                onChange={value => this.setState({ outletIds: value })}
                                                showSearch
                                                optionFilterProp="label"
                                            >
                                                <Option disabled value="">{i18n.t("Select Outlet")}</Option>
                                                {
                                                    operatorId ? outlets.map(outlet => (
                                                        <Option
                                                            key={outlet.id}
                                                            value={outlet.id}
                                                            label={`${outlet.outlet_fullname} (${outlet.outlet_name})`}
                                                        >
                                                            {outlet.outlet_fullname} ({outlet.outlet_name})
                                                        </Option>
                                                    )) : null
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="date">{i18n.t("Select Date")}: </label>
                                            <RangePicker
                                                onChange={this.handleDate}
                                                style={{ width: '100%' }}
                                                value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : null}
                                                ranges={{
                                                    'Today': [dayjs(), dayjs()],
                                                    'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                                    'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                                    'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                                    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                                    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={12}>
                                            <Button type="primary" onClick={this.handleFilter} className="w-100">
                                                {i18n.t("Filter")}
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button onClick={this.handleReset} className="w-100">
                                                {i18n.t("Reset")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                {this.state.loading ? <Loading /> : showReport && tables}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(UserUsageReport);
