import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { currency } from "../../../../dynamicController";

const WITHOUT_MINPRICE_LIMIT = process.env.REACT_APP_DRYER_WITHOUT_MINPRICE_LIMIT
const STORETYPE = process.env.REACT_APP_STORETYPE;

const DetailsSetting = ({ setPage, size, machine, setCart, cart, setModeSelection, modeSelection, setMode, mode, setQuantity, quantity, setPack, pack, user }) => {
    const [wash, setWash] = useState(false);
    const [dry, setDry] = useState(false);
    const [fold, setFold] = useState(false);
    const [washPrice, setWashPrice] = useState(null);
    const [dryPrice, setDryPrice] = useState(null);
    const [foldPrice, setFoldPrice] = useState(null);
    const [dryerPrice, setDryerPrice] = useState(null);
    const [dryerAmount, setDryerAmount] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        let washPrice = machine.priceData.filter(price => price.size === size && price.type === "washer").sort((a, b) => a.price - b.price)
        let modes = []
        for (let i = 0; i < washPrice.length; i++) {
            if (!modes.includes(washPrice[i].defaultmode)) {
                modes.push(washPrice[i].defaultmode)
            }
        }
        setModeSelection(modes)
        if (washPrice[0]) {
            setMode(washPrice[0].defaultmode)
            setWashPrice(washPrice[0])
            setWash(true)
        }

        const dryPackagePrice = machine.priceData.filter(price => price.size === size && price.type === "dryer")[0]
        if (dryPackagePrice) {
            setDryPrice(dryPackagePrice)
            const dryMachinePrice = machine.dryerPrice.filter(price => price.weight === dryPackagePrice.weight)[0]
            if (dryMachinePrice) {
                setDryerPrice(dryMachinePrice)
                setDryerAmount(dryMachinePrice.minprice)
                setDry(true)
            }
        }

        if (machine.priceData.filter(price => price.size === size && price.type === "fold")[0]) {
            setFoldPrice(machine.priceData.filter(price => price.size === size && price.type === "fold")[0])
            setFold(true)
        }
        setQuantity(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeMode = (m) => {
        setMode(m)
        let newWashPrice = machine.priceData.filter(price => price.size === size && price.type === "washer" && price.defaultmode === m)[0]
        setWashPrice(newWashPrice)
    }

    const minusQuantity = () => {
        if ((quantity - 1) >= 1) {
            setQuantity(quantity - 1)
        }
    }

    const addQuantity = () => {
        setQuantity(quantity + 1)
    }

    const minusDuration = () => {
        const step = parseFloat(machine.outletStep || 1)
        const afterMinus = parseFloat(dryerAmount) - step
        if (afterMinus >= parseFloat(dryerPrice.minprice)) {
            setDryerAmount(afterMinus)
        } else if (afterMinus >= step && WITHOUT_MINPRICE_LIMIT) {
            setDryerAmount(afterMinus)
        }
    }

    const addDuration = () => {
        const step = parseFloat(machine.outletStep || 1)
        const addedAmount = parseFloat(dryerAmount) + step
        const maxPrice = parseFloat(dryerPrice.maxprice || 20)
        if (addedAmount <= maxPrice) {
            setDryerAmount(addedAmount)
        }
    }

    const renderDuration = () => {
        if (dryerPrice.initialtime) {
            return parseFloat(dryerPrice.initialtime) + ((parseFloat(dryerAmount) - parseFloat(dryerPrice.minprice)) * parseFloat(dryerPrice.runtime))
        } else {
            return parseFloat(dryerAmount) * parseFloat(dryerPrice.runtime);
        }
    };

    const totalAmount = () => {
        let total = 0
        if (wash) {
            total += parseFloat(washPrice.price)
        }
        if (dry) {
            let dryPaymentAmount = parseFloat(dryerAmount) + parseFloat(dryPrice.price)
            total += parseFloat(dryPaymentAmount)
        }
        if (fold) {
            total += parseFloat(foldPrice.price)
        }
        let price = total * quantity
        return /\./g.test(price.toString()) ? price.toFixed(2) : price
    }

    const handleConfirm = () => {
        let currentCart = cart
        let currentPack = pack
        for (let i = 0; i < quantity; i++) {
            if (wash) {
                currentCart.push(
                    {
                        name: "Wash",
                        size,
                        capacity: washPrice.weight,
                        mode,
                        payment: washPrice.payment,
                        price: washPrice.price,
                        sequence: currentPack
                    }
                )
            }

            if (dry) {
                currentCart.push(
                    {
                        name: "Dry",
                        size,
                        capacity: dryPrice.weight,
                        payment: dryerAmount,
                        price: parseFloat(dryerAmount) + parseFloat(dryPrice.price),
                        machine_duration: renderDuration(),
                        sequence: currentPack
                    }
                )
            }

            if (fold) {
                currentCart.push(
                    {
                        name: "Fold",
                        size,
                        price: foldPrice.price,
                        sequence: currentPack
                    }
                )
            }

            currentPack += 1
        }
        setPack(currentPack)
        setCart(currentCart)
        setPage("Payment")
    }

    return (
        <div>
            {
                machine.dng_customize_service ? (
                    <div className="payment-section">
                        <p><b>{t("Select Services")}</b></p>

                        <div className="dng-selection-buttons mt-2">
                            {washPrice ? <button className={wash ? "selected" : ""} onClick={() => setWash(!wash)}>{t("Wash")}</button> : null}
                            {dryPrice && dryerPrice ? <button className={dry ? "selected" : ""} onClick={() => setDry(!dry)}>{t("Dry")}</button> : null}
                            {foldPrice ? <button className={fold ? "selected" : ""} onClick={() => setFold(!fold)}>{t("Fold")}</button> : null}
                        </div>
                    </div>
                ) : null
            }

            <div className="payment-section d-flex justify-content-between">
                <p><b>{t("Size")}</b></p>
                <p>{size}</p>
            </div>

            {
                wash ? (
                    <div className="payment-section">
                        <p><b>{t("Wash")}</b></p>
                        <p className="py-2" style={{ fontWeight: "500" }}>{t("Select Mode")}</p>
                        <div className="dng-selection-buttons">
                            {
                                modeSelection.map((m, index) => {
                                    return (<button key={index} onClick={() => changeMode(m)} className={mode === m ? "selected" : ""}>{t(m)}</button>)
                                })
                            }
                        </div>
                        {washPrice && washPrice.weight ? (
                            <div className="d-flex justify-content-between py-2">
                                <p style={{ fontWeight: "500" }}>{t("Capacity")}</p>
                                <p>{washPrice.weight}</p>
                            </div>
                        ) : null}
                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                        {washPrice ? (
                            <div className="d-flex justify-content-between pt-2">
                                <p style={{ fontWeight: "500" }}>{t("Price")}</p>
                                <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${washPrice.price} ${t("credit")}` : `${currency(machine.country)} ${washPrice.price}`}</b></p>
                            </div>
                        ) : null}
                    </div>
                ) : null
            }

            {
                dry ? (
                    <div className="payment-section">
                        <p><b>{t("Dry")}</b></p>
                        <p style={{ fontWeight: "500" }}>{t("Custom duration")}</p>
                        {
                            STORETYPE === "cleanpro" ? (
                                <div className="custom-duration mt-2">
                                    <p id="cleanpro-minus-amount" onClick={(e) => minusDuration()}>-</p>
                                    <h3 style={{ marginTop: "auto", marginBottom: "auto" }}>
                                        {renderDuration()} {t("min")}
                                    </h3>
                                    <p id="cleanpro-add-amount" onClick={(e) => addDuration()}>+</p>
                                </div>
                            ) : (
                                <div className="payment-amount">
                                    <span id="minus-amount" onClick={(e) => minusDuration()}>
                                        <i className="bi bi-dash-circle-fill"></i>
                                    </span>

                                    <div>
                                        <h3 className="m-0 p-0 font-weight-bold">
                                            {renderDuration()} {t("min")}
                                        </h3>
                                    </div>

                                    <span id="add-amount" onClick={(e) => addDuration()}>
                                        <i className="bi bi-plus-circle-fill"></i>
                                    </span>
                                </div>
                            )
                        }
                        {dryPrice && dryPrice.weight ? (
                            <div className="d-flex justify-content-between py-2">
                                <p style={{ fontWeight: "500" }}>{t("Capacity")}</p>
                                <p>{dryPrice.weight}</p>
                            </div>
                        ) : null}
                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                        {dryPrice ? (
                            <div className="d-flex justify-content-between pt-2">
                                <p style={{ fontWeight: "500" }}>{t("Price")}</p>
                                <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${parseFloat(dryerAmount) + parseFloat(dryPrice.price)} ${t("credit")}` : `${currency(machine.country)} ${parseFloat(dryerAmount) + parseFloat(dryPrice.price)}`}</b></p>
                            </div>
                        ) : null}
                    </div>
                ) : null
            }

            {
                fold ? (
                    <div className="payment-section">
                        <p><b>{t("Fold")}</b></p>
                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                        {foldPrice ? (
                            <div className="d-flex justify-content-between pt-2">
                                <p style={{ fontWeight: "500" }}>{t("Price")}</p>
                                <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${foldPrice.price} ${t("credit")}` : `${currency(machine.country)} ${foldPrice.price}`}</b></p>
                            </div>
                        ) : null}
                    </div>
                ) : null
            }

            <div className="payment-section">
                <p><b>{t("Quantity")}</b></p>
                {
                    STORETYPE === "cleanpro" ? (
                        <div className="custom-duration mt-2">
                            <p id="cleanpro-minus-amount" onClick={(e) => minusQuantity()}>-</p>
                            <h3 style={{ marginTop: "auto", marginBottom: "auto" }}>
                                {quantity}
                            </h3>
                            <p id="cleanpro-add-amount" onClick={(e) => addQuantity()}>+</p>
                        </div>
                    ) : (
                        <div className="payment-amount">
                            <span id="minus-amount" onClick={(e) => minusQuantity()}>
                                <i className="bi bi-dash-circle-fill"></i>
                            </span>

                            <div>
                                <h3 className="m-0 p-0 font-weight-bold">
                                    {quantity}
                                </h3>
                            </div>

                            <span id="add-amount" onClick={(e) => addQuantity()}>
                                <i className="bi bi-plus-circle-fill"></i>
                            </span>
                        </div>
                    )
                }
            </div>

            <div className="payment-section">
                <div className="d-flex justify-content-between">
                    <p style={{ fontWeight: "500" }}>{t("Total")}</p>
                    <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount()} ${t("credit")}` : `${currency(machine.country)} ${totalAmount()}`}</b></p>
                </div>
            </div>

            <div className="px-3 pb-3">
                <button
                    id="dng-add-item"
                    onClick={(e) => handleConfirm(e)}
                    className="btn form-control big-button"
                >
                    {t("Confirm")}
                </button>
            </div>

            <div className="px-3 pb-3">
                <button
                    id="back-to-size-page"
                    onClick={(e) => setPage("Size")}
                    className="btn form-control secondary-button"
                    style={{ height: "50px" }}
                >
                    {t("Back")}
                </button>
            </div>
        </div>
    )
}

export default DetailsSetting;