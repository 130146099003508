import dryerImg from '../../../../assets/images/machines/paymentDryer.png';
import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import PriceButton from '../../../../assets/images/logo/HotWarmColdButton.png';
import DryerPaymentLogo from '../../../../assets/images/logo/DryerPaymentLogo.png';
import { currency } from '../../../../dynamicController';

const CleanproDryer = ({ setPage, machine, amount, minusAmount, addAmount, outlet, notificationType, email, number, temperature,
    renderDuration, voucherId, discountCode, user, setSendThis, getPaymentAmount, setShowOverlay }) => {

    const handlePay = () => {
        let sendThis = {
            amount,
            machine,
            paymentAmount: getPaymentAmount("paymentAmount"),
            outlet,
            duration: renderDuration(),
            notificationType,
            email,
            sms_amount: getPaymentAmount("sms"),
            number,
            temperature,
            kiosk: true,
        };
        // let endPoint = "/api/nonuser/payment";

        if (user) {
            sendThis.voucherId = voucherId;
            sendThis.memberId = user.memberId;
            sendThis.discountCode = discountCode;
            sendThis.point_amount = getPaymentAmount("pointAmount");
            sendThis.point_currency = user.point_currency;
            // endPoint = "/api/user/payment";
        }
        setSendThis(sendThis)
        setPage('Payment');
    }

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        }}>
            <div style={{
                width: '1080px',
                height: '1920px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: '40px'
            }}>
                {machine.running ? (
                    <>
                        <h2 style={{ color: '#000', fontSize: '48px', fontWeight: '800', marginBottom: '40px' }}>
                            Machine is currently busy
                        </h2>

                        <img
                            src={dryerImg}
                            alt="Dryer"
                            style={{ width: "200px", height: "200px", marginBottom: "40px" }}
                        />

                        <h3 style={{ color: '#000', fontSize: '36px', marginBottom: '40px', fontWeight: 'normal' }}>
                            Do you want to extend drying time?
                        </h3>

                        <div
                            className="d-flex justify-content-between align-items-center mb-5"
                            style={{
                                fontSize: '36px',
                                backgroundColor: '#fff',
                                border: '2px solid #0000ff',
                                borderRadius: '60px',
                                padding: '10px 30px',
                                width: '400px'
                            }}
                        >
                            <button
                                onClick={minusAmount}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: '48px',
                                    fontWeight: 'bold',
                                    color: '#0000ff'
                                }}>
                                -
                            </button>

                            <div>
                                {currency(machine.country)}{parseFloat(amount).toFixed(2)} <br /> {renderDuration()} min
                            </div>

                            <button
                                onClick={addAmount}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: '48px',
                                    fontWeight: 'bold',
                                    color: '#0000ff'
                                }}>
                                +
                            </button>
                        </div>

                        <div className="d-flex justify-content-center position-relative" style={{ gap: '40px', marginTop: '40px' }}>
                            <div className="position-relative" style={{ width: '200px', height: '80px' }}>
                                <img
                                    src={YesButton}
                                    alt="Proceed Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={handlePay}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '32px',
                                        fontWeight: '600',
                                        color: '#fff',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    Proceed
                                </span>
                            </div>

                            <div className="position-relative" style={{ width: '200px', height: '80px' }}>
                                <img
                                    src={NoButton}
                                    alt="Cancel Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={() => setShowOverlay(false)}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '32px',
                                        fontWeight: '600',
                                        color: '#000',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    Cancel
                                </span>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 style={{ color: '#000', fontSize: '64px', fontWeight: '800', marginBottom: '60px' }}>
                            Select the time for
                        </h2>

                        <div className="position-relative" style={{ width: '300px', height: '300px', marginBottom: '60px' }}>
                            <div style={{ backgroundColor: '#004FC1', width: '100%', height: '100%', borderRadius: '10px', padding: '20px', position: 'relative' }}>
                                <img
                                    src={DryerPaymentLogo}
                                    alt="Dryer"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '64px',
                                        fontWeight: 'bold',
                                        color: '#000',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    {machine.name.split('-')[1]}
                                </span>
                            </div>

                            <div
                                style={{
                                    backgroundColor: '#004FC1',
                                    color: '#fff',
                                    textAlign: 'center',
                                    fontSize: '34px',
                                    fontWeight: '600',
                                    padding: '10px',
                                    borderRadius: '0 0 10px 10px',
                                    marginTop: '-10px',
                                }}
                            >
                                {machine.type} {machine.capacity}
                            </div>
                        </div>

                        <div className="position-relative" style={{ width: '600px', height: '180px', marginBottom: '60px', marginTop: '30px' }}>
                            <img
                                src={PriceButton}
                                alt="Price Display"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '48px',
                                    fontWeight: '600',
                                    color: '#000',
                                    pointerEvents: 'none'
                                }}
                            >
                                {currency(machine.country)}{parseFloat(amount).toFixed(2)} <br /> {renderDuration()} minutes
                            </span>
                        </div>

                        <h3 style={{ color: '#000', fontSize: '52px', marginBottom: '40px', fontWeight: 'normal' }}>
                            Do you want to add drying time?
                        </h3>

                        <div
                            className="d-flex justify-content-between align-items-center mb-5"
                            style={{
                                fontSize: '48px',
                                backgroundColor: '#fff',
                                border: '2px solid #0000ff',
                                borderRadius: '50px',
                                padding: '10px 30px',
                                width: '800px'
                            }}
                        >
                            <button
                                onClick={minusAmount}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: '72px',
                                    fontWeight: 'bold',
                                    color: '#0000ff'
                                }}>
                                -
                            </button>

                            <div>
                                {currency(machine.country)}{parseFloat(amount).toFixed(2)} <br /> {renderDuration()} min
                            </div>

                            <button
                                onClick={addAmount}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: '72px',
                                    fontWeight: 'bold',
                                    color: '#0000ff'
                                }}>
                                +
                            </button>
                        </div>

                        <div className="d-flex justify-content-center position-relative" style={{ gap: '60px', marginTop: '80px' }}>
                            <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                                <img
                                    src={YesButton}
                                    alt="Proceed Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={handlePay}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '48px',
                                        fontWeight: '600',
                                        color: '#fff',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    Proceed
                                </span>
                            </div>

                            <div className="position-relative" style={{ width: '300px', height: '100px' }}>
                                <img
                                    src={NoButton}
                                    alt="Cancel Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={() => setShowOverlay(false)}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '48px',
                                        fontWeight: '600',
                                        color: '#000',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    Cancel
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CleanproDryer;
