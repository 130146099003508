import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactStars from "react-rating-stars-component";
import i18n from "i18next";
import whatsapp from "../../../assets/images/whatapps.png";
import feedbackIcon from "../../../assets/images/icons/icn-feedback.png";
import { scannedCode } from "../../../redux/actions/paymentActions";
import { decrypt } from "../../../dynamicController";

import { Modal } from "react-bootstrap";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      clean: 0,
      washer: 0,
      dryer: 0,
      service: 0,
      number: "",
      outletId: "",
      comment: "",
      outlets: [],
      name: "",
      cleanproConfirm: false,
      country: "Malaysia"
    };
  }

  componentDidMount() {

    if (this.props.user) {
      this.setState({
        ...this.state,
        name: this.props.user.name,
        number: this.props.user.number,
        country: this.props.user.country
      });
    }

    axios
      .post("/api/user/getOutlet", { operatorId: this.props.operatorId })
      .then((res) => {
        this.setState({
          ...this.state,
          outlets: res.data.data.outlets,
          country: res.data.data.country,
        });

        if (this.props.payment) {
          const selectedOutlet = res.data.data.outlets.filter((outlet) => outlet.outlet_name === this.props.payment.outletName)[0];
          if (selectedOutlet) {
            this.setState({
              ...this.state,
              outletId: selectedOutlet.id,
              loading: false,
            });
          } else {
            this.setState({
              ...this.state,
              loading: false,
            });
          }
        } else if (this.props.outlet.machineNo && this.props.outlet.outletCode && this.props.outlet.operatorCode) {
          let sendThis = {
            machineNo: this.props.outlet.machineNo,
            operatorCode: this.props.outlet.operatorCode,
            outletCode: this.props.outlet.outletCode,
            operatorId: this.props.operatorId
          }

          axios
            .post("/api/user/getMachine", sendThis)
            .then(resp => {
              if (resp.data && resp.data.data) {
                const response = decrypt(resp.data.data)
                this.props.scanned(response);
                const selectedOutlet = res.data.data.outlets.filter((outlet) => outlet.outlet_name === response.outletName)[0];
                if (selectedOutlet) {
                  this.setState({
                    ...this.state,
                    outletId: selectedOutlet.id,
                    loading: false,
                  });
                }
              }

              this.setState({
                ...this.state,
                loading: false,
              });
            })
            .catch(err => {
              // mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
              this.setState({
                ...this.state,
                loading: false,
              });
            });
        } else {
          this.setState({
            ...this.state,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
      });
  }

  handleClean = (rating) => {
    this.setState({
      ...this.state,
      clean: rating,
    });
  };

  handleWasher = (rating) => {
    this.setState({
      ...this.state,
      washer: rating,
    });
  };

  handleDryer = (rating) => {
    this.setState({
      ...this.state,
      dryer: rating,
    });
  };

  handleService = (rating) => {
    this.setState({
      ...this.state,
      service: rating,
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  submitFeedback = () => {
    this.setState({
      ...this.state,
      cleanproConfirm: false,
      loading: true,
    });

    const { clean, washer, dryer, service, number, outletId, comment, name } = this.state;

    const sendThis = {
      clean,
      washer,
      dryer,
      service,
      number,
      comment,
      outletId,
      name,
    };

    axios
      .post("/api/user/feedback", sendThis)
      .then((res) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        mySwal
          .fire({
            title: i18n.t("Success"),
            text: i18n.t("Your feedback is submitted successfully"),
            icon: "success",
            confirmButtonText: i18n.t("Okay"),
            confirmButtonColor: "#004FC1",
            customClass: {
              confirmButton: "cleanpro-swal-btn"
            }
          })
          .then(() =>
            this.props.history.push(this.props.user ? "/" : "/welcome?operatorCode=" + this.props.operatorId)
          );
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { clean, washer, dryer, service, number, outletId, comment, name } = this.state;

    if (clean && washer && dryer && service && number && outletId) {

      const numberRegex = /^\d+$/;

      if (this.state.country === "Taiwan" && (!numberRegex.test(number) || number.length !== 10 || number.substring(0, 2) !== "09")) {
        mySwal.fire(i18n.t("Error"), i18n.t("Invalid Phone Number"), "error");
      } else if (this.state.country === "Malaysia" && (!numberRegex.test(number) || number.length <= 9 || number.length >= 12 || number.substring(0, 2) !== "01")) {
        mySwal.fire(i18n.t("Error"), i18n.t("Invalid Phone Number"), "error");
      } else {
        if (STORETYPE === "cleanpro") {
          this.setState({
            ...this.state,
            cleanproConfirm: true,
          });
        } else {
          mySwal
            .fire({
              title: i18n.t("Confirmation"),
              text: i18n.t("Are you sure you want to submit your feedback?"),
              icon: "question",
              showCancelButton: true,
              cancelButtonText: i18n.t("No"),
              confirmButtonText: i18n.t("Yes"),
              cancelButtonColor: "#d33",
              customClass: {
                confirmButton: "confirm-submit-feedback-yes",
                cancelButton: "confirm-submit-feedback-no",
              },
            })
            .then((click) => {
              if (click.isConfirmed && click.value) {
                this.setState({
                  ...this.state,
                  loading: true,
                });

                const sendThis = {
                  clean,
                  washer,
                  dryer,
                  service,
                  number,
                  comment,
                  outletId,
                  name,
                };

                axios
                  .post("/api/user/feedback", sendThis)
                  .then((res) => {
                    this.setState({
                      ...this.state,
                      loading: false,
                    });
                    mySwal
                      .fire(i18n.t("Success"), i18n.t("Your feedback is submitted successfully"), "success")
                      .then(() =>
                        this.props.history.push(this.props.user ? "/" : "/welcome?operatorCode=" + this.props.operatorId)
                      );
                  })
                  .catch((err) => {
                    this.setState({
                      ...this.state,
                      loading: false,
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                  });
              }
            });
        }
      }
    } else {
      mySwal.fire(i18n.t("Info"), i18n.t("Please fill in all the required fields before submit."), "info");
    }
  };

  render() {
    return (
      <div id="setting-page">
        <div className="feedback" id="setting-main">
          <div id="user-header">
            <h5>
              <i
                onClick={(e) => this.props.history.goBack()}
                className="bi bi-chevron-left me-3"
              ></i>{" "}
              <strong>{i18n.t("Feedback")}</strong>
            </h5>
          </div>

          <div id="user-body">
            <h1 className="text-center pt-3 px-3">
              {STORETYPE === "cleanpro"
                ? i18n.t("Let us know your feedback")
                : i18n.t("Give us your feedback!")}
            </h1>
            {STORETYPE === "cuci" ? (
              <div className="pt-3 d-flex px-2">
                <div>
                  <img
                    src={whatsapp}
                    alt="whatsapp-icon"
                    className="img-fluid"
                    style={{ width: "80px" }}
                    onClick={() =>
                      (window.location = "https://wa.me/60129278893")
                    }
                  />
                </div>
                <div>
                  {i18n.t(
                    "If require urgent response, please contact us through whatapp"
                  )}
                  , <a href="https://wa.me/60129278893">0129278893</a>
                </div>
              </div>
            ) : null}
            <div className="py-3">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group px-5">
                  {STORETYPE !== "cleanpro" && <p>{i18n.t("Name")}</p>}
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder={i18n.t("Type your name here (optional)")}
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>

                <div className="form-group px-5">
                  {STORETYPE !== "cleanpro" && (
                    <p>
                      {i18n.t("Phone Number")}{" "}
                      <i style={{ color: "red" }}>*{i18n.t("required")}</i>
                    </p>
                  )}
                  <input
                    type="number"
                    name="number"
                    id="number"
                    className="form-control"
                    placeholder={i18n.t("Type your number here")}
                    onChange={this.handleChange}
                    value={this.state.number}
                    required
                  />
                </div>

                <div className="form-group px-5">
                  {STORETYPE !== "cleanpro" && (
                    <p>
                      {i18n.t("Outlet")}{" "}
                      <i style={{ color: "red" }}>*{i18n.t("required")}</i>
                    </p>
                  )}
                  <select
                    name="outletId"
                    id="outletId"
                    className="form-select"
                    value={this.state.outletId}
                    onChange={this.handleChange}
                  >
                    <option value="" disabled>
                      {i18n.t("Select an outlet")}
                    </option>
                    {this.state.outlets.map((outlet) => (
                      <option key={outlet.id} value={outlet.id}>
                        {outlet.outlet_fullname}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group pt-3 px-5">
                  <p>
                    {i18n.t("Outlet Cleanliness")}{" "}
                    <i style={{ color: "red" }}>
                      * {STORETYPE !== "cleanpro" && i18n.t("required")}
                    </i>
                  </p>
                  <ReactStars
                    count={5}
                    onChange={this.handleClean}
                    size={55}
                    activeColor="#ffd700"
                    value={this.state.clean}
                  />
                  <div
                    style={{ marginLeft: "5px" }}
                    className="t-light-g d-flex justify-content-between"
                  >
                    <p>{i18n.t("Poor")}</p>
                    <p>{i18n.t("Good")}</p>
                  </div>
                </div>

                {STORETYPE === "cleanpro" ? <hr /> : null}

                <div className="form-group px-5">
                  <p>
                    {i18n.t("Washer Machine Performance")}{" "}
                    <i style={{ color: "red" }}>
                      * {STORETYPE !== "cleanpro" && i18n.t("required")}
                    </i>{" "}
                  </p>
                  <ReactStars
                    count={5}
                    onChange={this.handleWasher}
                    size={55}
                    activeColor="#ffd700"
                    value={this.state.washer}
                  />
                  <div
                    style={{ marginLeft: "5px" }}
                    className="t-light-g d-flex justify-content-between"
                  >
                    <p>{i18n.t("Poor")}</p>
                    <p>{i18n.t("Good")}</p>
                  </div>
                </div>

                {STORETYPE === "cleanpro" ? <hr /> : null}

                <div className="form-group px-5">
                  <p>
                    {i18n.t("Dryer Machine Performance")}{" "}
                    <i style={{ color: "red" }}>
                      * {STORETYPE !== "cleanpro" && i18n.t("required")}
                    </i>{" "}
                  </p>
                  <ReactStars
                    count={5}
                    onChange={this.handleDryer}
                    size={55}
                    activeColor="#ffd700"
                    value={this.state.dryer}
                  />
                  <div
                    style={{ marginLeft: "5px" }}
                    className="t-light-g d-flex justify-content-between"
                  >
                    <p>{i18n.t("Poor")}</p>
                    <p>{i18n.t("Good")}</p>
                  </div>
                </div>

                {STORETYPE === "cleanpro" ? <hr /> : null}

                <div className="form-group px-5">
                  <p>
                    {i18n.t("Customer Service")}{" "}
                    <i style={{ color: "red" }}>
                      * {STORETYPE !== "cleanpro" && i18n.t("required")}
                    </i>{" "}
                  </p>
                  <ReactStars
                    count={5}
                    onChange={this.handleService}
                    size={55}
                    activeColor="#ffd700"
                    value={this.state.service}
                  />
                  <div
                    style={{ marginLeft: "5px" }}
                    className="t-light-g d-flex justify-content-between"
                  >
                    <p>{i18n.t("Poor")}</p>
                    <p>{i18n.t("Good")}</p>
                  </div>
                </div>

                <div className="form-group px-5">
                  {STORETYPE !== "cleanpro" && <p>{i18n.t("Comment")}</p>}
                  <textarea
                    id="comment"
                    name="comment"
                    style={{ height: STORETYPE === "cleanpro" && "150px" }}
                    className="form-control"
                    placeholder={
                      STORETYPE === "cleanpro"
                        ? i18n.t("Leave addtional feedback here (optional)")
                        : i18n.t("Type your comment here (optional)")
                    }
                    onChange={this.handleChange}
                    value={this.state.comment}
                  ></textarea>
                </div>

                <div className="px-5">
                  {this.state.loading ? (
                    <div className="text-center">
                      <div
                        className="spinner-border"
                        style={{ color: "#ffd109" }}
                        role="status"
                      >
                        <span className="sr-only">Loading ...</span>
                      </div>
                    </div>
                  ) : (
                    <input
                      id="submit-feedback"
                      type="submit"
                      value={
                        STORETYPE === "cleanpro"
                          ? i18n.t("Submit feedback")
                          : i18n.t("SUBMIT")
                      }
                      className="btn big-button form-control mt-3"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.cleanproConfirm}
          onHide={(e) => this.setState({
            ...this.state,
            cleanproConfirm: false,
          })}
          centered
        >
          <Modal.Body>
            <div className={this.props.theme}>
              <div className="text-center">
                <img src={feedbackIcon} alt="feedback-icon" className="img-fluid" style={{ width: "30%" }} />
              </div>
              <p style={{ fontSize: "22px", fontWeight: 500 }} className="text-center pt-3">{i18n.t("Please confirm")}</p>
              <p style={{ fontSize: "16px", fontWeight: 300 }} className="text-center">{i18n.t("Do you want to proceed with this feedback?")}</p>
              <div className="d-flex justify-content-around pt-3">
                <button id="cleanpro-cancel-feedback" onClick={() => this.setState({ ...this.state, cleanproConfirm: false })} style={{ fontSize: "16px", fontWeight: 500, width: "45%", height: "50px" }} className="btn secondary-button">
                  {i18n.t("Cancel")}
                </button>
                <button id="cleanpro-submit-feedback" onClick={() => this.submitFeedback()} style={{ width: "45%" }} className="btn big-button">
                  {i18n.t("Yes")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    operatorId: state.user.operatorId,
    payment: state.payment.machine,
    theme: state.theme.theme,
    outlet: state.payment.outlet
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scanned: (data) => dispatch(scannedCode(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
