import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import StorePerformace from "./StorePerformace";
import UserPerformance from "./UserPerformance";
import VoucherRedemption from "./VoucherRedemption";
import UserDetails from "./UserDetails";
import TokenReport from "./TokenReport";
import TokenDetail from "./TokenDetail";
import TokenPurchaseHistory from "./TokenPurchaseHistory";
import SmsReport from "./SmsReport";
import Settings from "./Settings";
import Token from "./Token";
import Referral from "./Referral";
import Customize from "./Customize";
import Faq from "./Faq";
import FaqList from "./FaqList";
import AddFaq from "./AddFaq";
import AddFaqGroup from "./AddFaqGroup";
import EditFaq from "./EditFaq";
import EditFaqGroup from "./EditFaqGroup";
import News from "./News";
import AddNews from "./AddNews";
import EditNews from "./EditNews";
import MemberDetailsReport from "./MemberDetailsReport";
import UserRewards from "./UserRewards";
import RewardHistory from "./RewardHistory";
import UserMigration from "./UserMigration";
import DiscountCode from "./DiscountCode";
import DiscountCodeReport from "./DiscountCodeReport";
import VoucherCodeGenerator from "./VoucherCodeGenerator";
import VoucherCodeReport from "./VoucherCodeReport";
import UserFloatingTokenReport from "./UserFloatingTokenReport";
import EPaymentReport from "./EPaymentReport";
import UserUsageReport from "./UserUsageReport";
import UserFloatingPointReport from "./FloatingPointReport";
import ReferralRewardHistoryReport from "./ReferralRewardHistoryReport";

const DistributorDashboard = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/distributor/dashboard" component={Dashboard} />
				<Route exact path="/distributor/outlet/performance" component={StorePerformace} />
				<Route exact path="/distributor/user/performance" component={UserPerformance} />
				<Route exact path="/distributor/voucher/redemption" component={VoucherRedemption} />
				<Route exact path="/distributor/token/report" component={TokenReport} />
				<Route exact path="/distributor/token/report/detail" component={TokenDetail} />
				<Route exact path="/distributor/token/report/purchasehistory" component={TokenPurchaseHistory} />
				<Route exact path="/distributor/user/details" component={UserDetails} />
				<Route exact path="/distributor/user/details/memberDetails" component={MemberDetailsReport} />
				<Route exact path="/distributor/giveaway" component={UserRewards} />
				<Route exact path="/distributor/giveaway/history" component={RewardHistory} />
				<Route exact path="/distributor/sms/report" component={SmsReport} />
				<Route exact path="/distributor/voucher/setting" component={Settings} />
				<Route exact path="/distributor/token/setting" component={Token} />
				<Route exact path="/distributor/referral" component={Referral} />
				<Route exact path="/distributor/setting/customize" component={Customize} />
				<Route exact path="/distributor/faq" component={Faq} />
				<Route exact path="/distributor/faq/list" component={FaqList} />
				<Route exact path="/distributor/faq/group/add" component={AddFaqGroup} />
				<Route exact path="/distributor/faq/add" component={AddFaq} />
				<Route exact path="/distributor/faq/group/edit" component={EditFaqGroup} />
				<Route exact path="/distributor/faq/edit" component={EditFaq} />
				<Route exact path="/distributor/news" component={News} />
				<Route exact path="/distributor/news/add" component={AddNews} />
				<Route exact path="/distributor/news/edit" component={EditNews} />
				<Route exact path="/distributor/migration" component={UserMigration} />
				<Route exact path="/distributor/discountCode" component={DiscountCode} />
				<Route exact path="/distributor/discountCode/report" component={DiscountCodeReport} />
				<Route exact path="/distributor/voucherCode" component={VoucherCodeGenerator} />
				<Route exact path="/distributor/voucherCode/report" component={VoucherCodeReport} />
				<Route exact path="/distributor/floatingToken/report" component={UserFloatingTokenReport} />
				<Route exact path="/distributor/epayment/report" component={EPaymentReport} />
				<Route exact path="/distributor/userUsage/report" component={UserUsageReport} />
				<Route exact path="/distributor/floatingPoint/report" component={UserFloatingPointReport} />
				<Route exact path="/distributor/referralReward/report" component={ReferralRewardHistoryReport} />
				
				<Redirect to="/distributor/dashboard" />
			</Switch>
		</div>
	);
};

export default DistributorDashboard;