import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import { checkToken, kioskSaveOutletId } from "../../redux/actions/kioskActions";
import queryString from 'query-string';
import CleanproDashboard from './pages/CleanproDashboard';
const STORETYPE = process.env.REACT_APP_STORETYPE

const Kiosk = ({ isAuthenticated, checkToken, saveOutletId }) => {

    const location = useLocation()

    useEffect(() => {
        const { outletId } = queryString.parse(location.search);

        if (outletId) {
            saveOutletId(outletId)
        }

        checkToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="kiosk-page">
            {
                isAuthenticated && STORETYPE === "cleanpro"
                    ? <CleanproDashboard />
                    : isAuthenticated
                        ? <Dashboard />
                        : <Login />
            }
        </div>
    );

};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.kiosk.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        saveOutletId: (data) => dispatch(kioskSaveOutletId(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kiosk);