import { connect } from 'react-redux';
import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import PaymentDone from '../../../../assets/images/logo/PaymentDone.png';
import './Responsive.css'

const ThankYou = ({ setPage, data, machineType }) => {
    return (
        <div id="thank-you-screen" className="d-flex flex-column align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px", position: "relative" }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt="Home Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt="FAQ Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Faq', 'ThankYou')}
                    />
                    <img
                        src={BackLogo}
                        alt="Back Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && data?.outlet_fullname && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data?.outlet_fullname}
                    </h2>
                )}
            </div>

            <div className="content d-flex flex-column align-items-center justify-content-center" style={{ height: '100%', textAlign: 'center', marginTop: '150px' }}>

                <img
                    src={PaymentDone}
                    alt="FAQ Logo"
                    style={{ height: '200px', marginBottom: '20px' }}
                />

                <h2 style={{ color: '#004FC1', fontSize: '60px', fontWeight: '600', marginBottom: '20px' }}>
                    Thank you for payment!
                </h2>

                <p style={{ color: '#000', fontSize: '40px', fontWeight: '600', lineHeight: '1.4', margin: '20px 0' }}>
                    {machineType === 'Vending Machine' ? (
                        <>
                            Reminder: <br />
                            Please collect your item. <br />
                            from the Vending Machine
                        </>
                    ) : (
                        <>
                            Reminder: <br />
                            Please select temperature <br />
                            at machine first <br />
                            & press ‘START’ <br />
                            button at <br />
                            your machine
                        </>
                    )}
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
    };
};

export default connect(mapStateToProps, null)(ThankYou);
