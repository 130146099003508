import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from "../../../dynamicController";
import { Select, Button, Row, Col } from 'antd';

const mySwal = withReactContent(Swal);
const { Option } = Select;

class SmsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: false,
            data: [],
            operatorId: "",
            smsCredit: 0
        };
    }

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.sms_report) {
            return this.props.history.push('/distributor');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = value => this.setState({
        ...this.state,
        operatorId: value
    });

    handleFilter = e => {
        const { operatorId } = this.state;

        if (operatorId) {

            const selectedOperator = this.props.distributor.admin.filter(admin => admin.id === operatorId);

            this.setState({
                ...this.state,
                loading: true,
            });

            axios
                .post("/api/admin/report/sms", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data,
                        smsCredit: selectedOperator[0].sms_credit
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        } else {
            mySwal.fire(i18n.t("Info"), i18n.t("Please select operator"), "info");
        }
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            operatorId: "",
            data: [],
            smsCredit: 0
        });
    };

    render() {
        let data = {
            columns: [
                {
                    label: i18n.t("Phone Number"),
                    field: "number",
                    sort: "asc"
                },
                {
                    label: i18n.t("Message Sent"),
                    field: "msg",
                    sort: "asc"
                },
                {
                    label: i18n.t("Date Sent"),
                    field: "date",
                    sort: "asc"
                }
            ],
            rows: []
        };

        let csv = [];

        if (this.state.data.length > 0) {
            for (let i = 0; i < this.state.data.length; i++) {
                const dataObject = {
                    number: this.state.data[i].number,
                    msg: this.state.data[i].message,
                    date: this.state.data[i].createdAt
                };
                data.rows.push(dataObject);

                const csvDataObject = {
                    number: `=""${this.state.data[i].number}""`,
                    msg: this.state.data[i].message,
                    date: new Date(this.state.data[i].createdAt).toLocaleString("en-GB")
                }
                csv.push(csvDataObject);
            }
        }

        const csvData = {
            headers: [
                { label: "Phone Number", key: "number" },
                { label: "Message Sent", key: "msg" },
                { label: "Date Sent", key: "date" }
            ],
            data: csv
        };

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">{i18n.t("SMS Report")}</h5>
                                <h4 className="m-0 p-0">{i18n.t("Remaining SMS Credit")}: {this.state.smsCredit || 0}</h4>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label htmlFor="operatorId">{i18n.t("Select Operator")}: </label>
                                            <Select
                                                id="operatorId"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Operator")}
                                                value={this.state.operatorId}
                                                onChange={this.handleChange}
                                            >
                                                <Option disabled value="">{i18n.t("Select Operator")}</Option>
                                                {this.props.distributor.admin.map(admin => (
                                                    <Option key={admin.id} value={admin.id}>
                                                        {admin.username}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>

                                    <div className="pt-2 d-flex">
                                        <Button type="primary" onClick={this.handleFilter} className="w-100 me-3">
                                            {i18n.t("Filter")}
                                        </Button>
                                        <Button onClick={this.handleReset} className="w-100 ms-3">
                                            {i18n.t("Reset")}
                                        </Button>
                                    </div>

                                </div>
                                <div className="text-center mt-5">{this.state.loading ? <Loading /> : (
                                    <div>
                                        <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`sms_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                {i18n.t("Download Report")}
                                            </CSVLink>
                                        </div>
                                    </div>
                                )}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(SmsReport);
