import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { currency } from "../../../../dynamicController";
import { Modal } from "react-bootstrap";

import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";
import LaundroVending from "../../../user/utilities/payment/Vending/LaundroVending";

import CleanproVendingPayment from "../cleanpro/VendingPayment"

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const VendingPayment = ({ machine, outlet, user, theme, userLogout, setSendThis, setPage, setShowOverlay }) => {
	const [amount, setAmount] = useState(STORETYPE === "cuci" ? 2 : 1);
	const [insufficient, setInsufficient] = useState(false);
	const [razerBody, setRazerBody] = useState(null);

	const razerButtonClick = useRef();

	const { t } = useTranslation();

	useEffect(() => {
		if (razerBody) {
			razerButtonClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [razerBody]);

	const minusAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount - step;
		if (newAmount) setAmount(newAmount);
	};

	const addAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount + step;
		if (newAmount <= 100) setAmount(newAmount);
	};

	const handleSubmit = e => {
		if (amount && amount > 0) {
			e.target.disabled = true;
			if (machine.online && machine.outletStatus === "online") {
				if (user && user.tokenSetting && user.epayment) {
					// E-payment or E-token
					mySwal
						.fire({
							title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
							text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
							showDenyButton: true,
							showCancelButton: true,
							cancelButtonText: t("Cancel"),
							denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
							confirmButtonText: STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token"),
							denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#67b668",
							confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#CFA82C" : "#2778c4",
							customClass: {
								confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : STORETYPE === "washup" ? "washup-swal-btn e-wallet" : "e-wallet",
								denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : STORETYPE === "washup" ? "washup-swal-btn e-token" : "e-token",
								cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : STORETYPE === "washup" ? "washup-swal-btn cancel-payment" : "cancel-payment"
							}
						})
						.then(click => {
							if (click.isDenied) {
								// E WALLET PAYMENT
								let sendThis = {
									amount: parseFloat(amount).toFixed(2),
									paymentAmount: parseFloat(amount).toFixed(2),
									machine,
									outlet,
									kiosk: true
								};

								let endPoint = "/api/nonuser/payment";

								if (user) {
									sendThis.voucherId = null;
									sendThis.memberId = user.memberId;
									endPoint = "/api/user/payment";
								}

								//console.log({ sendThis, endPoint });
								mySwal
									.fire({
										title: t("Confirmation"),
										text: `${t("Pay")} ${currency(machine.country)} ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
										icon: "question",
										showCancelButton: true,
										cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
										confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
										reverseButtons: STORETYPE === "cleanpro" ? true : false,
										confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
										customClass: {
											confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
											cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : ""
										}
									})
									.then(result => {
										if (result.isConfirmed) {
											mySwal
												.fire({
													text: t("Processing ..."),
													allowOutsideClick: false,
													didOpen: () => {
														mySwal.showLoading();
														axios
															.post(endPoint, sendThis)
															.then(res => {
																if (res.status === 200 && res.statusText === "OK") {
																	if (res.data.status === "rm") {
																		e.target.disabled = false;
																		mySwal.close();
																		window.location.href = res.data.data;
																	} else if (res.data.status === "laundro") {
																		e.target.disabled = false;
																		mySwal.close();
																		userLogout();
																	} else if (res.data.status === "razer") {
																		e.target.disabled = false;
																		mySwal.close();
																		setRazerBody(res.data.data)
																	}
																}
															})
															.catch(err => mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false)));
													}
												})
												.then(() => {
													e.target.disabled = false;
												});
										} else {
											e.target.disabled = false;
										}
									});
							} else if (click.isConfirmed) {
								// TOKEN PAYMENT
								if (parseFloat(user.token) < amount) {
									if (STORETYPE === "cleanpro") {
										setInsufficient(true);
										e.target.disabled = false;
									} else {
										mySwal
											.fire({
												title: t("Insufficient e-Tokens"),
												text: t("Reload your e-token now?"),
												showCancelButton: true,
												cancelButtonText: t("Cancel"),
												confirmButtonText: t("Reload now!"),
												confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
												customClass: {
													confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
													cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
												}
											})
											.then(result => {
												if (result.isConfirmed && result.value) userLogout();
												e.target.disabled = false;
											});
									}
								} else {
									const sendThis = {
										amount: parseFloat(amount).toFixed(2),
										paymentAmount: parseFloat(amount).toFixed(2),
										machine,
										memberId: user.memberId,
										token: true,
										outlet,
										kiosk: true
									};

									mySwal
										.fire({
											title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
											html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${parseFloat(amount).toFixed(2)}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
											icon: "question",
											showCancelButton: true,
											cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
											confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
											reverseButtons: STORETYPE === "cleanpro" ? true : false,
											confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
											customClass: {
												confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
												cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
											}
										})
										.then(result => {
											if (result.isConfirmed) {
												mySwal
													.fire({
														text: t("Processing ..."),
														allowOutsideClick: false,
														didOpen: () => {
															mySwal.showLoading();
															axios
																.post("/api/user/payment", sendThis)
																.then(res => {
																	if (res.status === 200 && res.statusText === "OK") {
																		if (res.data.status === "rm") {
																			e.target.disabled = false;
																			mySwal.close();
																			window.location.href = res.data.data;
																		} else if (res.data.status === "laundro") {
																			e.target.disabled = false;
																			mySwal.close();
																			userLogout()
																		} else if (res.data.status === "razer") {
																			e.target.disabled = false;
																			mySwal.close();
																			setRazerBody(res.data.data)
																		}
																	}
																})
																.catch(err => {
																	mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
																});
														}
													})
													.then(() => {
														e.target.disabled = false;
													});
											} else {
												e.target.disabled = false;
											}
										});
								}
							} else {
								e.target.disabled = false;
							}
						});
				} else if (user && user.tokenSetting && !user.epayment) {
					// Only E-Token
					if (parseFloat(user.token) < amount) {
						if (STORETYPE === "cleanpro") {
							setInsufficient(true);
							e.target.disabled = false;
						} else {
							mySwal
								.fire({
									title: t("Insufficient e-Tokens"),
									text: t("Reload your e-token now?"),
									showCancelButton: true,
									cancelButtonText: t("Cancel"),
									confirmButtonText: t("Reload now!"),
									confirmButtonColor: STORETYPE === "washup" ? "#004c64" : "#2778c4",
									customClass: {
										confirmButton: STORETYPE === "washup" ? "washup-swal-btn reload-token" : "reload-token",
										cancelButton: STORETYPE === "washup" ? "washup-swal-btn cancel-reload-token" : "cancel-reload-token"
									}
								})
								.then(result => {
									if (result.isConfirmed && result.value) userLogout();
									e.target.disabled = false;
								});
						}
					} else {
						const sendThis = {
							amount: parseFloat(amount).toFixed(2),
							paymentAmount: parseFloat(amount).toFixed(2),
							machine,
							memberId: user.memberId,
							token: true,
							outlet,
							kiosk: true
						};

						mySwal
							.fire({
								title: t("Confirmation"),
								html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${parseFloat(amount).toFixed(2)}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
								icon: "question",
								showCancelButton: true,
								cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
								confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
								reverseButtons: STORETYPE === "cleanpro" ? true : false,
								confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
								customClass: {
									confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
									cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
								}
							})
							.then(result => {
								if (result.isConfirmed) {
									mySwal
										.fire({
											text: t("Processing ..."),
											allowOutsideClick: false,
											didOpen: () => {
												mySwal.showLoading();
												axios
													.post("/api/user/payment", sendThis)
													.then(res => {
														if (res.status === 200 && res.statusText === "OK") {
															if (res.data.status === "rm") {
																e.target.disabled = false;
																mySwal.close();
																window.location.href = res.data.data;
															} else if (res.data.status === "laundro") {
																e.target.disabled = false;
																mySwal.close();
																userLogout()
															} else if (res.data.status === "razer") {
																e.target.disabled = false;
																mySwal.close();
																setRazerBody(res.data.data)
															}
														}
													})
													.catch(err => {
														mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
													});
											}
										})
										.then(() => {
											e.target.disabled = false;
										});
								} else {
									e.target.disabled = false;
								}
							});
					}
				} else {
					// Only E-payment
					let sendThis = {
						amount: parseFloat(amount).toFixed(2),
						paymentAmount: parseFloat(amount).toFixed(2),
						machine,
						outlet,
						kiosk: true
					};

					let endPoint = "/api/nonuser/payment";

					if (user) {
						sendThis.voucherId = null;
						sendThis.memberId = user.memberId;
						endPoint = "/api/user/payment";
					}

					mySwal
						.fire({
							title: t("Confirmation"),
							text: `${t("Pay")} ${currency(machine.country)} ${parseFloat(amount).toFixed(2)} ${t("to")} ${machine.name}?`,
							icon: "question",
							showCancelButton: true,
							cancelButtonText: t("No"),
							confirmButtonText: t("OK"),
							confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
							customClass: {
								confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
								cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
							}
						})
						.then(result => {
							if (result.isConfirmed) {
								mySwal
									.fire({
										text: t("Processing ..."),
										allowOutsideClick: false,
										didOpen: () => {
											mySwal.showLoading();
											axios
												.post(endPoint, sendThis)
												.then(res => {
													if (res.status === 200 && res.statusText === "OK") {
														if (res.data.status === "rm") {
															e.target.disabled = false;
															mySwal.close();
															window.location.href = res.data.data;
														} else if (res.data.status === "laundro") {
															e.target.disabled = false;
															mySwal.close();
															userLogout()
														} else if (res.data.status === "razer") {
															e.target.disabled = false;
															mySwal.close();
															setRazerBody(res.data.data)
														}
													}
												})
												.catch(err => mySwal.fire("Error", err.response.data.error, "error")
													.then(() => {
														e.target.disabled = false
														if (err.response.data.error === "Please log in to make the payment") {
															userLogout()
														}
													}));
										}
									})
									.then(() => {
										e.target.disabled = false;
									});
							} else {
								e.target.disabled = false;
							}
						});
				}
			} else {
				mySwal
					.fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
					.then(() => (e.target.disabled = false));
			}
		} else {
			mySwal.fire(t("Error"), t("Payment amount should be greater than 0"), "error")
		}
	};

	return (
		<div id="vending-machine" className="mt-4">

			{
				STORETYPE === "cleanpro"
					? <CleanproVendingPayment
						machine={machine}
						user={user}
						setPage={setPage}
						outlet={outlet}
						setSendThis={setSendThis}
						setShowOverlay={setShowOverlay}
					/>
					: <LaundroVending
						amount={amount}
						setAmount={setAmount}
						machine={machine}
						minusAmount={minusAmount}
						addAmount={addAmount}
						handleSubmit={handleSubmit}
					/>
			}

			{
				razerBody ? (
					<div>
						<form action={razerBody.paymentDomain} method="post">
							<input type="hidden" name="amount" value={razerBody.amount} />
							<input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
							<input type="hidden" name="orderid" value={razerBody.orderid} />
							{razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
							{razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
							{razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
							<input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
							<input type="hidden" name="country" value={razerBody.country} />
							<input type="hidden" name="vcode" value={razerBody.vcode} />
							<input type="hidden" name="currency" value={razerBody.currency} />
							<input type="hidden" name="returnurl" value={razerBody.returnurl} />
							<input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
							<input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

							<input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
						</form>
					</div>
				) : null
			}
			<Modal
				show={insufficient}
				onHide={(e) => setInsufficient(false)}
				keyboard={false}
				centered
			>
				<Modal.Body>
					<div className={theme}>
						<img
							className="d-block"
							src={insufficientToken}
							style={{ width: "64px", margin: "0 auto" }}
							alt=""
						/>
						<h2 className="align-center my-3"> {t("Insufficient balance")}</h2>
						<p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p>
						<div className="d-flex justify-content-around pt-3">
							<button id="cleanpro-cancel-topup" onClick={() => setInsufficient(false)} style={{ height: "50px", width: "40%" }} className="btn secondary-button">
								{t("Back")}
							</button>
							<button id="cleanpro-topup-now" onClick={() => userLogout()} className="btn big-button" style={{ width: "50%" }}>
								{t("Topup now")}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
}
export default VendingPayment;