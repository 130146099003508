import React, { useState, useEffect } from 'react';
import StartScreen from './cleanpro/StartScreen';
import Option from './cleanpro/Option';
import SelectService from './cleanpro/SelectService';
import Machines from './cleanpro/Machines';
import Payment from './cleanpro/Payment';
import ThankYou from './cleanpro/Thankyou';
import Faq from './cleanpro/Faq';

const CleanproDashboard = () => {
    const [page, setPage] = useState('Start');
    const [machineType, setMachineType] = useState('');
    const [user, setUser] = useState(null);
    const [sendThis, setSendThis] = useState(null)
    const [previousPage, setPreviousPage] = useState(null);
    const [machine, setMachine] = useState(null);

    useEffect(() => {
        const disableZoom = (e) => {
            if (e.ctrlKey || e.metaKey || e.key === 'Meta' || e.key === 'Control') {
                e.preventDefault();
            }
        };

        const disablePinchZoom = (e) => {
            if (e.touches && e.touches.length > 1) {
                e.preventDefault();
            }
        };

        const disableContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener('wheel', disableZoom, { passive: false });
        document.addEventListener('keydown', disableZoom, { passive: false });
        document.addEventListener('touchmove', disablePinchZoom, { passive: false });
        document.addEventListener('touchstart', disablePinchZoom, { passive: false });

        document.addEventListener('contextmenu', disableContextMenu, { passive: false });

        return () => {
            document.removeEventListener('wheel', disableZoom);
            document.removeEventListener('keydown', disableZoom);
            document.removeEventListener('touchmove', disablePinchZoom);
            document.removeEventListener('touchstart', disablePinchZoom);
            document.removeEventListener('contextmenu', disableContextMenu);
        };
    }, []);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    const handlePageChange = (newPage, prevPage = null) => {
        setPreviousPage(prevPage);
        setPage(newPage);
    };

    const userLogout = () => {
        setUser(null)
        setPage('Start')
    }

    const handleMachineTypeSelect = (type) => {
        setMachineType(type);
        setPage('Machines');
    };

    return (
        <div>
            {page === 'Start' && <StartScreen setPage={setPage} />}
            {page === 'Option' && <Option setPage={handlePageChange} setUser={setUser} />}
            {page === 'SelectService' && (
                <SelectService
                    onMachineTypeSelect={handleMachineTypeSelect}
                    setPage={handlePageChange}
                    user={user}
                />
            )}
            {page === 'Machines' && (
                <Machines
                    machineType={machineType}
                    setPage={handlePageChange}
                    user={user}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    machine={machine}
                    setMachine={setMachine}
                />
            )}
            {page === 'Payment' && <Payment setPage={handlePageChange} sendThis={sendThis} machine={machine} />}
            {page === 'ThankYou' && <ThankYou setPage={handlePageChange} machineType={machineType} />}
            {page === 'Faq' && <Faq setPage={handlePageChange} previousPage={previousPage} />}
        </div>
    );
};

export default CleanproDashboard;