import { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import cleanprologo from '../../../../assets/images/logo/Logo_Cleanproplus-blue.png';
import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import CleanproConfirmButton from '../../../../assets/images/logo/CleanproConfirmButton.png';
import CleanproBackButton from '../../../../assets/images/logo/CleanproBackButton.png';
import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import './Responsive.css'

const mySwal = withReactContent(Swal);

const Option = ({ setPage, outletId, token, setUser, data }) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        const numberRegex = /^\d+$/;

        if (mobileNumber && data.country === "Taiwan" && (!numberRegex.test(mobileNumber) || mobileNumber.length !== 10 || mobileNumber.substring(0, 2) !== "09")) {
            mySwal.fire("Error", "Invalid Phone Number for Taiwan", "error");
            return;
        } else if (mobileNumber && data.country === "Malaysia" && (!numberRegex.test(mobileNumber) || mobileNumber.length <= 9 || mobileNumber.length >= 12 || mobileNumber.substring(0, 2) !== "01")) {
            mySwal.fire({
                title: "Error",
                icon: "error",
                html: `Please enter the correct phone number format.`,
            });
            return;
        }
        if (event.target.checkValidity()) {
            setShowOverlay(true);
        } else {
            event.target.reportValidity();
        }
    };

    const handleClose = () => {
        setShowOverlay(false);
    };

    const handleYes = () => {
        setLoading(true);

        axios.post('/api/kiosk/checkUser', {
            token,
            outletId,
            number: mobileNumber
        }).then(response => {
            setUser(response.data.data.user);
            setLoading(false);
            setPage('SelectService');
        }).catch(err => {
            setLoading(false);
            setShowOverlay(false);
            mySwal.fire('Error', err.response.data.error || 'An unknown error occurred', 'error');
        })
    };

    return (
        <div id="member-login-screen" className="d-flex flex-column justify-content-between align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px" }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt="Home Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt="FAQ Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Faq', 'Option')}
                    />
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data.outlet_fullname}
                    </h2>
                )}
            </div>

            <div className="content d-flex flex-column align-items-center" style={{ marginTop: '450px' }}>
                <img src={cleanprologo} alt="Cleanpro Plus Logo" style={{ height: "150px", marginBottom: "20px" }} />
                <h1 className="text-primary" style={{ fontSize: '90px', fontWeight: '600', marginBottom: '50px' }}>Member</h1>
                <h4 className="text-dark" style={{ fontSize: '50px', fontWeight: '700', marginBottom: '60px', textAlign: 'center' }}>
                    Please enter your mobile number <br /> to start our benefits
                </h4>
                <h3 className="text-dark" style={{ fontSize: '50px', fontWeight: '700', marginBottom: '20px' }}>Mobile Number</h3>
                <Form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '850px' }}>
                    <Form.Group controlId="mobileNumber" style={{ width: '100%', marginBottom: '40px' }}>
                        <Form.Control
                            type="number"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            required
                            autoComplete="off"
                            placeholder={data.country === "Taiwan" ? "Example: 09xxxxxxxx" : "Example: 012xxxxxxx"}
                            style={{
                                height: '100px',
                                fontSize: '36px',
                                textAlign: 'center',
                                borderRadius: '50px',
                                border: '2px solid #004FC1',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                            }}
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                        />
                    </Form.Group>
                    <div className="buttons d-flex justify-content-center" style={{ width: '100%', gap: '20px' }}>
                        <div className="position-relative" style={{ width: '270px', height: '100px' }}>
                            <button
                                type="submit"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <img
                                    src={CleanproConfirmButton}
                                    alt="Confirm Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        color: '#fff',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    Confirm
                                </span>
                            </button>
                        </div>

                        <div className="position-relative" style={{ width: '270px', height: '100px' }}>
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => setPage('Start')}
                            >
                                <img
                                    src={CleanproBackButton}
                                    alt="Back Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        color: '#000',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    Back
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>

            </div>

            <div className="footer text-center mt-auto">
            </div>

            {showOverlay && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                    textAlign: 'center',
                    padding: '20px'
                }}>
                    <h2 style={{
                        color: '#000',
                        fontSize: '50px',
                        fontWeight: '600',
                        marginBottom: '30px',
                        textAlign: 'center'
                    }}>Please confirm <br /> your number</h2>

                    <h1 style={{
                        color: '#000',
                        fontSize: '50px',
                        fontWeight: '500',
                        marginBottom: '40px'
                    }}>
                        {mobileNumber} <br /> is your number?
                    </h1>

                    <h4 style={{
                        color: '#000',
                        fontSize: '40px',
                        fontWeight: '500',
                        marginBottom: '50px',
                        textAlign: 'center'
                    }}>
                        Complete your signup <br /> to unlock more benefits
                    </h4>

                    <div className="d-flex justify-content-center position-relative" style={{ gap: '40px' }}>
                        <div className="position-relative" style={{ width: '250px', height: '80px' }}>
                            {loading ? (
                                <div className="text-center">
                                    <div
                                        className="spinner-border"
                                        style={{ color: "#004FC1" }}
                                        role="status"
                                    >
                                        <span className="sr-only">Loading ...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <img
                                        src={YesButton}
                                        alt="Yes Button"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        onClick={handleYes}
                                        disabled={loading}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '36px',
                                            fontWeight: '600',
                                            color: '#fff',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        Yes
                                    </span>
                                </>
                            )}
                        </div>

                        <div className="position-relative" style={{ width: '250px', height: '80px' }}>
                            <img
                                src={NoButton}
                                alt="No Button"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={handleClose}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '36px',
                                    fontWeight: '600',
                                    color: '#000',
                                    pointerEvents: 'none'
                                }}
                            >
                                No
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.kiosk.token,
        outletId: state.kiosk.outletId,
        data: state.kiosk.data
    };
};

export default connect(mapStateToProps, null)(Option);